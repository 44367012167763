import Container from './Container'
import Typograpy from './Typography'
import Icon from './old/Icon'

const Card = ({
  title = `Titulo`,
  subtitle = ``,
  children,
  headerBackgroundColor,
  headerPadding = `1em`,
  headerColor = `white`,
  titleFontSize = `18px`,
  subtitleFontSize = `14px`,
  alert = false,
  ...props
}) => {
  return (
    <Container
      width="30%"
      marginBottom="2rem"
      position="relative"
      border="1px solid #e3e3e3"
      withShadow
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      {...props}
    >
      {alert && (
        <Icon
          type="bell"
          backgroundColor="red"
          color="white"
          fontSize="20px"
          position="absolute"
          borderRadius="15px"
          padding="5px"
          top="-10px"
          right="-5px"
        />
      )}
      <Container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width="100%"
        padding={headerPadding}
        borderBottom="2px solid"
        borderColor="primary.2"
        backgroundColor={headerBackgroundColor}
      >
        <Typograpy
          fontWeight="500"
          color={headerColor}
          fontSize={titleFontSize}
        >
          {title.toUpperCase()}
        </Typograpy>
        {subtitle && (
          <Typograpy
            fontWeight={subtitleFontSize}
            color={headerColor}
            fontSize="14px"
          >
            {subtitle.toUpperCase()}
          </Typograpy>
        )}
      </Container>
      {children}
    </Container>
  )
}
export default Card
